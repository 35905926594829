<template>
  <div class="index">
    <div class="main_layout">
      <a-alert
        message="有问题可以先来这里看看。"
        class="ant-alert-banner"
        type="success"
        show-icon
      />
      <div class="main">
        <a-collapse activeKey="activeKey" :accordion="true">
          <a-collapse-panel
            v-for="(item, index) in helpData"
            :key="index + 1"
            :header="item.title"
          >
            <template v-if="item.type === 'text'">
              <p v-for="(i, index) in item.content" :key="index">
                {{ i }}
              </p>
            </template>
            <template v-if="item.type === 'image'">
              <img src="../../assets/help1.png" alt="" />
              <img src="../../assets/help2.png" alt="" />
              <img src="../../assets/help3.png" alt="" />
              <img src="../../assets/help4.png" alt="" />
            </template>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { helpData } from "@/assets/staticData/staticData";

export default {
  name: "index",
  components: {},
  data() {
    return {
      activeKey: ["1"],
      helpData: []
    };
  },
  methods: {},
  computed: {},
  created() {
    this.helpData = helpData;
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.main {
  margin-top: 24px;
}
</style>
